<template>
  <div className="w-full container">
    <iframe
      className="w-full h-full"
      :src="pdfUrl"
      seamless="seamless"
      scrolling="no"
      frameborder="0"
      allowtransparency="true"
      allowfullscreen="true"
    />
  </div>
</template>

<script>
import { gunsaImpression } from '@/utils/trackers'

export default {
  name: 'Pdf',
  created () {
    this.initPage()
  },
  computed: {
    pdfUrl () {
      return `https://drive.google.com/file/d/${this.id}/preview`
    },
    id () {
      return this.$route.query.id
    }
  },
  methods: {
    initPage () {
      if (!this.id) {
        this.$router.push('Home')
      } else {
        gunsaImpression({
          page: 'Pdf',
          title: this.id,
          value: `www.gkigunsa.com/pdf?id=${this.id}`
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 84vh;
}
</style>
